import React from 'react';
import { useDispatch } from 'react-redux';

import API from 'api/api';
import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

import NotForFieldItem from './NotForFieldItem';
import classNames from 'classnames';

const NotForField = (props) => {
  const {
    callerId,
    classPrefix,
    isNewContactCreation,
    notForClients,
    isCreationDisabled = false,
    hideTitle = false,
  } = props;

  const dispatch = useDispatch();
  const ignoreIds = notForClients?.map(item => item.caller_ignore_id);

  const handleSaveContact = (profileItem) => {
    if (!isNewContactCreation) {
      API.addClientNotFor(profileItem.caller_id, profileItem.caller_ignore_id)
        .catch(err => console.error(err));
    }
  };

  const handleRemoveItem = (profileItem) => {
    if (!!props.handleRemoveItem) {
      props.handleRemoveItem(profileItem);
      return;
    }

    if (!isNewContactCreation && window.confirm('Are you sure you want to remove this contact from Not for?')) {
      API.removeClientNotFor(profileItem.caller_id, profileItem.caller_ignore_id)
        .catch(console.error);
    }
  };

  const handleOpenModal = () => {
    dispatch(openModal(MODAL_TYPES.notForContact, {
      ignoreIds,
      callerId,
      saveContact: handleSaveContact
    }));
  };

  return (
    <div className={classModifier(`${classPrefix}__field`, "not-for")}>
      {!hideTitle && (<h4 className={`${classPrefix}__field-title`}>Not for:</h4>)}

      {notForClients?.length
        ? notForClients.map((item, index) => {
          return (
            <NotForFieldItem
              key={item.id || index}
              ignoreName={item.callerIgnore?.fn || item.ignoreName}
              removeItem={() => handleRemoveItem(item)}
            />
          )
        })
        : <div className={`${classPrefix}__field-content`}>
          Empty field...
        </div>
      }

      {!isCreationDisabled && (
        <div className={`${classPrefix}__btn-wrapper`}>
          <button
            type="button"
            className={classModifier(`${classPrefix}__add-field-btn`, "contact-add")}
            onClick={() => handleOpenModal()}
          >
            <ICONS.plusCircle className="adr-book-contact-form__btn-add-icon"/>
          </button>
        </div>
      )}
    </div>
  );
};

export default NotForField;
