import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';
import { selectModalCount } from 'redux/selectors/selectors';

import ICONS from 'assets/icons';
import BookingsByDateModal from 'components/BookingsByDateModal/BookingsByDateModal';
import { classModifier } from 'utils';

const HeaderModalBookingBtn = () => {
  const [isBookingPopupOpen, setIsBookingPopupOpen] = useState(false);

  const {
    visible,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps,
    tooltipRef,
    triggerRef,
  } = usePopperTooltip(
    {
      offset: [0, 18],
      trigger: 'click',
      placement: 'top',
      visible: isBookingPopupOpen,
      onVisibleChange: setIsBookingPopupOpen,
      closeOnOutsideClick: false,
    }
  );

  const modalCount = useSelector((state) => selectModalCount(state));
  const isModalOpened = !!modalCount;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (triggerRef?.contains(event.target)) {
        return null;
      }

      if (tooltipRef && !tooltipRef.contains(event.target) && !isModalOpened) {
        setIsBookingPopupOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [isBookingPopupOpen, isModalOpened, tooltipRef]);

  return (
    <>
      <button className="header-modal-btns__button" ref={setTriggerRef}>
        <ICONS.booking
          className={classModifier("header-modal-btns__icon", visible && 'active')}
          width="20px"
          height="20px"
        />
      </button>

      {visible &&
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ style: { zIndex: 90 } })}
        >
          <BookingsByDateModal />
        </div>
      }
    </>
  );
};

export default HeaderModalBookingBtn;
