import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { classModifier } from 'utils';
import { selectContactById } from 'redux/selectors/selectors';

import './AdrBookPage.scss';
import AdrBookTags from 'containers/AdrBookTags/AdrBookTags';
import AdrBookHeader from 'containers/AdrBookHeader/AdrBookHeader';
import AdrBookListWrap from 'containers/AdrBookListWrap/AdrBookListWrap';
import AdrBookContactFormWrap from 'containers/AdrBookContactFormWrap/AdrBookContactFormWrap';
import ContactsForMergeBtn from 'components/ContactsForMergeBtn/ContactsForMergeBtn';
import AdrBookSelectedContactsListItem from 'containers/AdrBookListWrap/components/AdrBookSelectedContactsListItem/AdrBookSelectedContactsListItem';
import { useOpenCommModuleModal, useSetSocketPageStatus } from 'hooks';
import { SOCKET_PAGES_TITLES } from 'config/constants';

const AdrBookPage = () => { 
  const [undoDataProps, setUndoData] = useState({});
  const [edited, setEdited] = useState(false);
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [selectedContactsList, setSelectedContactsList] = useState([]);
  const [tagsScrollIsBottom, setTagsScrollIsBottom] = useState(false);

  const activeContact = useSelector(state => selectContactById(state, state.addressBook.active));
  const contactsPending = useSelector(state => state.addressBook.contactsPending);

  const callbacksRef = useRef(null);

  useOpenCommModuleModal()
  useSetSocketPageStatus(SOCKET_PAGES_TITLES.addressBook)

  const handleSelectMode = () => {
    const currList = isSelectMode
      ? []
      : [...selectedContactsList, 
          activeContact && {
            id: activeContact.id,
            contactType: activeContact.type,
            selectedContact: activeContact
          },
        ];

    setSelectedContactsList(currList.filter(Boolean));
    setIsSelectMode(!isSelectMode);
  };

  const handleAddSelectContact = (contact) => {
    if(selectedContactsList.length < 2) {
      setSelectedContactsList([
        ...selectedContactsList, 
        { 
          style: {}, 
          id: contact.id, 
          contactType: contact.type, 
          selectedContact: contact 
        }
      ])
    }
  }

  const handleDeleteSelectedContact = (id) => {
    setSelectedContactsList(selectedContactsList.filter(contact => contact.id !== id))
  }

  const setPrevState = () => {
    if (callbacksRef?.current) {
      return callbacksRef.current.setPrevState();
    }
  };

  const saveContact = () => {
    if (callbacksRef?.current) {
      return callbacksRef.current?.saveContact && callbacksRef.current.saveContact();
    }
  }
  
  return (
    <div className={classModifier("adr-book-page", contactsPending && 'pending')}>
      <AdrBookHeader
        setPrevState={setPrevState}
        isSelectMode={isSelectMode}
        selectedContactsList={selectedContactsList}
        setSelectedContactsList={setSelectedContactsList}
        undoDataProps={undoDataProps}
        saveContact={saveContact}
        edited={edited}
      />

      <main className="adr-book-page__main">
        <AdrBookTags 
          tagsScrollIsBottom={tagsScrollIsBottom}
          setTagsScrollIsBottom={setTagsScrollIsBottom}
        />

        <AdrBookListWrap 
          isSelectMode={isSelectMode}
          handleSelectMode={handleSelectMode}
          onAddSelectContact={handleAddSelectContact}
          selectedContactsList={selectedContactsList}
          dataPending={undoDataProps.pending}
        />

        {isSelectMode 
          ? <div className="adr-book-page__selected-list-wrap">
              <h3 className="adr-book-page__selected-list-wrap-title">
                Selected ({selectedContactsList.length})
              </h3>

              <ul className="adr-book-page__selected-list">
                {selectedContactsList.map(contact => {
                  return <AdrBookSelectedContactsListItem id={contact.id} onDelete={handleDeleteSelectedContact}/>
                })}
              </ul>
            </div>
          : <AdrBookContactFormWrap 
              setUndoData={setUndoData} 
              callbacksRef={callbacksRef}
              setEdited={setEdited}
              dataPending={undoDataProps.pending}
            />
        }

        <ContactsForMergeBtn 
          tagsScrollIsBottom={tagsScrollIsBottom}
        />
      </main>
    </div>
  )
};

export default AdrBookPage;
