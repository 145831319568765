import React, { Component, createRef, forwardRef } from "react";
import { VariableSizeList as List } from "react-window";
import { connect } from "react-redux";

import LS from 'utils/localStorageAPI';
import { classModifier, isEmptyObj } from "utils";
import { ADR_BOOK_CONTACT_TYPE_FILTERS } from "config/constants";
import { changeContactType, updateTagsFilter } from "redux/ducks/addressBook";

import "./AdrBookList.scss";
import AutoSizer from "react-virtualized-auto-sizer";
import AdrBookListScrollWrapper from "./AdrBookListScrollWrapper";
import Spinner from "components/UI/Spinner/Spinner";
import AdrBookItem from "../AdrBookItem/AdrBookItem";

class AdrBookList extends Component {
  expectedListLength = this.props.limit;
  listRef = createRef();

  componentDidMount() {
    this.expectedListLength =
      this.props.list.length >= this.expectedListLength
        ? this.props.list.length
        : this.expectedListLength;

    this.checkIfListIsEmptyAndLoadMore();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mode !== this.props.mode) {
      if (prevProps.isShowEx === this.props.isShowEx) {
        this.expectedListLength =
          this.props.list.length >= this.props.limit
            ? this.props.list.length
            : this.props.limit;
      } else {
        this.expectedListLength = this.props.limit;
      }

      this.checkIfListIsEmptyAndLoadMore();
      return;
    }
    // if we remove contact
    if (prevProps.list.length - 1 === this.props.list.length) {
      this.expectedListLength -= 1;
    }
    // if we add new contact
    if (prevProps.list.length + 1 === this.props.list.length) {
      this.expectedListLength += 1;
    }

    this.checkIfListIsEmptyAndLoadMore();
  }

  checkIfListIsEmptyAndLoadMore = () => {
    if (this.listRef?.clientHeight && this.listRef.clientHeight > 3000 && this.expectedListLength >= 90) {
      return;
    }
    // Calculate if the list is not fully filled and the visible part is empty
    if (this.listRef?.clientHeight && this.listRef.clientHeight > 1500) {
      this.expectedListLength += this.props.limit;
      this.props.loadMore && this.props.loadMore(this.props.list.length);
    }
  };

  handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    const scrollBottom = scrollHeight - scrollTop - clientHeight;

    if (
      scrollBottom < 1500 &&
      this.expectedListLength === this.props.list.length
    ) {
      this.expectedListLength += this.props.limit;
      this.props.loadMore && this.props.loadMore(this.props.list.length); // pass offset to loadMore func
    }
  };

  renderListItem = ({ index, style }) => {
    const ListItem = this.props.listItem;

    return (
      <ListItem
        style={style}
        index={index}
        id={this.props.list[index]}
        contactType={this.props.contactType}
        lastIndex={this.props.list.length - 1}
        isSelectMode={this.props.isSelectMode}
        dataPending={this.props.dataPending}
        changeContactType={this.props.changeContactType}
        selectedContactsList={this.props.selectedContactsList}
        onAddSelectContact={this.props.onAddSelectContact}
      />
    );
  };

  clearTagsFilter = () => {
    const isActiveTagsFilter = !isEmptyObj(this.props.activeTags);

    this.props.changeContactType(ADR_BOOK_CONTACT_TYPE_FILTERS.ALL, this.props.userId);

    if (isActiveTagsFilter) {
      this.props.updateTagsFilter();
    }
  };

  renderGlobalSearchBtn = (isSearchResult) => {
    if(this.props.contactType !== ADR_BOOK_CONTACT_TYPE_FILTERS.ALL) {
      return (
        <div className={classModifier("adr-book-list__global-search-btn-wrap", isSearchResult && 'is-search-result')}>
          <button
            onClick={() => {
              this.props.changeContactType(ADR_BOOK_CONTACT_TYPE_FILTERS.ALL, this.props.userId);
            }}
            className="adr-book-list__global-search-btn"
          >
            Try Searching All Contacts
          </button>
        </div>
      )
    }
  };

  outerListWrap = forwardRef((props, ref) => (
    <AdrBookListScrollWrapper
      {...props}
      forwardedRef={ref}
      customOnScroll={this.handleScroll}
    />
  ));

  innerListWrap = forwardRef(({ style, ...rest }, ref) => {
    const activeTags = Object.keys(this.props.activeTags);
    const isTagsContactsSearch = !!activeTags.length;

    return (
      <>
        <div
          ref={ref}
          style={{
            ...style,
            paddingTop: 5,
          }}
          {...rest}
        />

        {isTagsContactsSearch && (
          <button
            onClick={this.clearTagsFilter}
            className="adr-book-list__global-search-btn"
          >
            Search "All contacts"
          </button>
        )}
      </>
    );
  });

  render() {
    const activeTags = Object.keys(this.props.activeTags);
    const isTagsContactsSearch = !!activeTags.length;
    const isSearchResult = !!this.props.list.length;

    if (!isSearchResult) {
      return (
        <div
          className={classModifier(
            "adr-book-list",
            "centered",
          )}
        >
          {this.props.pending ? (
            <Spinner spinnerSize="40px" />
          ) : isTagsContactsSearch ? (
            <div className="adr-book-list__no-results">
              <h3 className="adr-book-list__no-results-desc">
                No results found
                <br />
                Try global search instead
              </h3>
              <button
                onClick={this.clearTagsFilter}
                className="adr-book-list__global-search-btn"
              >
                Try Searching "All contacts"
              </button>
            </div>
          ) : (
            <>
              <span>(no items)</span>

              {this.renderGlobalSearchBtn(isSearchResult)}
            </>
          )}
        </div>
      );
    }

    return (
      <div className={classModifier("adr-book-list", this.props.contactsPending && 'pending')} ref={ref => this.listRef = ref}>
        {LS.getItem('adrBookSearch') && this.renderGlobalSearchBtn(isSearchResult)}

        {this.props.isSelectMode && 
          <div className="adr-book-list__selected-list-wrapper">
            {this.props.selectedContactsList?.map((item, idx, arrItems) => {
              return (
                <AdrBookItem
                  key={idx}
                  index={idx}
                  isSelectMode
                  isActiveSelect
                  lastIndex={arrItems.length - 1}
                  dataPending={this.props.dataPending}
                  {...item}
                />
              )
            })}
          </div>
        }

        <AutoSizer className="adr-book-list__auto-sizer-wrapper">
          {({ width, height }) => (
            <List
              outerElementType={this.outerListWrap}
              innerElementType={this.innerListWrap}
              itemCount={this.props.list.length}
              itemSize={() => 50}
              height={height}
              width={width}
              className="adr-book-list__list"
            >
              {this.renderListItem}
            </List>
          )}
        </AutoSizer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.user.id,
  activeTags: state.addressBook.tags.active,
  contactType: state.addressBook.contactType,
  contactsPending: state.addressBook.contactsPending,
});

const mapDispatchToProps = {
  updateTagsFilter,
  changeContactType,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdrBookList);
