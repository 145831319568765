import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { ADR_BOOK_SEARCH_FILTERS } from 'config/constants';
import { setContactsSearchFilters } from 'redux/ducks/addressBook';

import './AdrBookSearchFilters.scss';


const adrBookSearchFilterOptions = [
  { label: 'ID', value: ADR_BOOK_SEARCH_FILTERS.ID },
  { label: 'Full name', value: ADR_BOOK_SEARCH_FILTERS.FN },
  { label: 'Diva ID', value: ADR_BOOK_SEARCH_FILTERS.DIVA_ID },
  { label: 'Link URL', value: ADR_BOOK_SEARCH_FILTERS.LINK_URL },
  { label: 'Address', value: ADR_BOOK_SEARCH_FILTERS.ADDRESS },
  { label: 'Tel number', value: ADR_BOOK_SEARCH_FILTERS.TEL_NUMBER },
  { label: 'Email', value: ADR_BOOK_SEARCH_FILTERS.EMAIL, },
  { label: 'Telegram nickname', value: ADR_BOOK_SEARCH_FILTERS.TEL_NICKNAME, },
  { label: 'Notes', value: ADR_BOOK_SEARCH_FILTERS.NOTES, },
];


const AdrBookSearchFilters = () => {
  const [initialValue, setInitialValue] = useState([]);

  const searchFilters = useSelector(state => state.addressBook.searchFilters);
  const dispatch = useDispatch();

  const filterSelectRef = useRef();

  useEffect(() => {
    const filters = adrBookSearchFilterOptions.filter(item => searchFilters.includes(item.value));
    setInitialValue(filters);
  }, [searchFilters]);

  const handleSearchFilterChange = (option) => {
    const filters = option.map(filter => filter.value)
    dispatch(setContactsSearchFilters(filters));
  };

  return (
    <div className='adr-book-search-filters'>
      <Select
        isMulti
        isSearchable={false}
        value={initialValue}
        ref={filterSelectRef}
        className="react-select"
        classNamePrefix="react-select"
        hideSelectedOptions={false}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: null,
          Placeholder: () => 
            <ICONS.settings 
              className={classModifier(
                'adr-book-search-filters__btn', 
                  [searchFilters.length > 0 && 'selected']
                )}
            />
        }}
        closeMenuOnSelect={false}
        controlShouldRenderValue={false}
        options={adrBookSearchFilterOptions}
        onChange={handleSearchFilterChange}
        getOptionValue={option => option.value}
      />
    </div>
  )
};

export default AdrBookSearchFilters;