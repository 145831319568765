import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import {
  updateContactsList,
  toggleContactPin,
  toggleContactMark,
  CHAT_CONTACTS_LIMIT,
  toggleContactBlock,
  removeContactFilter,
  getMarkedContacts,
  getContactsWithUnread,
  getRelatedContacts,
  getAvailableGirls,
  getOnlyHotels,
  getOnlyAgents,
  getNotTextedForLong,
  getDebtGirlContacts,
  getTemporaryGirlContacts,
  getActiveChats,
  ALL_FILTER,
  UNREAD_FILTER,
  MARKED_FILTER,
  SERVICES_FILTER,
  AGENTS_FILTER,
  RELATED_FILTER,
  AVAILABLE_GIRLS_FILTER,
  NOT_TEXTED_FOR_LONG,
  ACTIVE_CHATS,
  toggleSearchSource,
  toggleMessageSource,
  toggleMainFilter,
  TEMPORARY_FILTER,
  DEBT_FILTER,
} from 'redux/ducks/contacts';
import { CONTACT_TABS, CONTACT_TYPES } from 'config/constants';

import API from 'api/api';
import { usePrevious, useDidUpdate, useDidMount, useCancelToken } from 'hooks';
import { selectGirlsList } from 'redux/selectors/selectors';
import { openModal, MODAL_TYPES, closeAllModals } from 'redux/ducks/activeWindows';
import { updateSearchContactsByType, stopContactsSearchByType, searchContactsByType, cleanContactsUnreadCount } from 'redux/ducks/contacts';
import LS from 'utils/localStorageAPI';

import './SidebarGirlsChatList.scss';
import SidebarContactsList from 'containers/SidebarChatLists/components/SidebarContactsList/SidebarContactsList';
import ContactItem from 'containers/SidebarChatLists/components/SidebarContactsList/ContactItem';
import SidebarContactsFilters from 'containers/SidebarChatLists/components/SidebarContactsFilters/SidebarContactsFilters';
import SidebarHeader from 'containers/SidebarChatLists/components/SidebarHeader/SidebarHeader';
import SearchListContactItem
  from '../../../components/SearchList/components/SearchListContactItem/SearchListContactItem';

const SEARCH_LIST_SIZE = 20;

const SidebarGirlsChatList = ({ openModal, girlsUnreadCount, girlsSearchSource, closeAllModals, ...props }) => {
  const [animationClass, setAnimationClass] = useState({
    girls: '',
  });

  const prevUnreadGirls = usePrevious(girlsUnreadCount);
  const prevGirlsSearchSource = usePrevious(girlsSearchSource);

  // To cancel axios http request
  const { newCancelToken, cancelPrevRequest } = useCancelToken();

  useDidMount(() => {
    if (props.searchGirls) {
      props.searchContactsByType(
        props.searchGirls,
        CONTACT_TABS.GIRLS,
        girlsSearchSource,
        newCancelToken(),
      );
    }

    if (props.girlsActiveFilter !== ALL_FILTER) {
      updateGirlList(true)(CONTACT_TYPES.GIRL, 0, true);
    }
  });

  useDidUpdate(() => {
    if (props.searchGirls) return;

    if (props.girlsActiveFilter !== ALL_FILTER) {
      updateGirlList()(CONTACT_TYPES.GIRL);
    }
  }, [props.girlsMainActiveFilter])

  useDidUpdate(() => {
    if ((prevUnreadGirls !== girlsUnreadCount) && girlsUnreadCount) {
      setAnimationClass(prevState => ({ ...prevState, girls: '' }));
    }
    else {
      if (!girlsUnreadCount && prevUnreadGirls) {
        setAnimationClass({ ...animationClass, girls: '' });
      }
    }
  }, [girlsUnreadCount]);

  useDidUpdate(() => {
    startSearch(props.searchGirls);
  }, [girlsSearchSource]);

  useDidUpdate(() => {
    LS.setItem('girlsSearch', props.searchGirls);
  }, [props.searchGirls]);

  useDidUpdate(() => {
    LS.setItem('girlsActiveFilter', props.girlsActiveFilter);
  }, [props.girlsActiveFilter]);

  const updateGirlList = (ignoreSearch = false) => {
    if (props.searchGirls && !ignoreSearch) {
      return props.updateSearchContactsByType;
    }
    else if (props.girlsActiveFilter === MARKED_FILTER) {
      return props.getMarkedContacts;
    }
    else if (props.girlsActiveFilter === UNREAD_FILTER) {
      return props.getContactsWithUnread;
    }
    else if (props.girlsActiveFilter === AVAILABLE_GIRLS_FILTER) {
      return props.getAvailableGirls;
    }
    else if (props.girlsActiveFilter === RELATED_FILTER) {
      return props.getRelatedContacts;
    }
    else if (props.girlsActiveFilter === SERVICES_FILTER) {
      return props.getOnlyHotels;
    }
    else if (props.girlsActiveFilter === AGENTS_FILTER) {
      return props.getOnlyAgents;
    }
    else if (props.girlsActiveFilter === NOT_TEXTED_FOR_LONG) {
      return props.getNotTextedForLong;
    }
    else if (props.girlsActiveFilter === TEMPORARY_FILTER) {
      return props.getTemporaryGirlContacts;
    }
    else if (props.girlsActiveFilter === DEBT_FILTER) {
      return props.getDebtGirlContacts;
    }
    else if (props.girlsActiveFilter === ACTIVE_CHATS) {
      return props.getActiveChats;
    }
    return props.updateContactsList;
  };

  const blockContact = (contact) => {
    if (contact.blocked) {
      return props.toggleContactBlock(contact);
    }

    return openModal(MODAL_TYPES.blockForm, { contact: contact.id });
  };

  const fetchSearchContacts = ({ query, offset, cancelToken, ignoredId, type }) => {
    return API.searchContacts(type, query, offset, SEARCH_LIST_SIZE, cancelToken)
      .then(({ data }) => ({
        newItems: data,
        newHasMore: data.length === SEARCH_LIST_SIZE,
      }))
      .catch(console.error);
  };

  const generateContextItems = (contact) => {
    const items = props.girlsActiveFilter !== ACTIVE_CHATS
      ?[
        {
          text: `${contact.pinned ? 'Unp' : 'P'}in contact`,
          action: () => props.toggleContactPin(contact),
        },
        {
          text: `${contact.marked ? 'Unm' : 'M'}ark contact`,
          action: () => props.toggleContactMark(contact),
        },
        {
          text: `${contact.blocked ? 'Un' : 'B'}lock contact`,
          action: () => blockContact(contact),
        },
        {
          text: 'Merge contact to ...',
          action: () => {
            openModal(MODAL_TYPES.searchList, {
              ignoredId: contact.id,
              fetchData: (params) => fetchSearchContacts({ ...params, type: contact.type, }),
              itemComponent: SearchListContactItem,
              isMergeList: true,
              onChoose: (selectedContact) => {
                openModal(
                  MODAL_TYPES.mergeContacts,
                  {
                    donorContact: contact,
                    acceptorContact: selectedContact,
                    onSubmit: () => {
                      closeAllModals();
                    },
                  },
                );
              },
            });
          },
        },
        {
          text: `${contact.is_active_chat ? 'Remove from' : 'Add to'} active chats`,
          action: () => API.toggleContactActiveChat(contact),
        },
      ]
      :[
        {
          text: "Remove from active chats",
          action: () => API.toggleContactActiveChat(contact),
        },
      ];

    items.push();

    return items;
  };

  // const formatTotalCount = count => count > 99999 ? "99999+" : count;

  const getFiltersByType = useCallback(() => {
    const filters = [
      { name: ALL_FILTER, action: () => props.removeContactFilter('girls') },
      { name: MARKED_FILTER, action: () => props.getMarkedContacts(2) },
      { name: UNREAD_FILTER, action: () => props.getContactsWithUnread(2) },
      { name: RELATED_FILTER, action: () => props.getRelatedContacts(2) },
      { name: SERVICES_FILTER, action: () => props.getOnlyHotels('girls') },
      { name: AGENTS_FILTER, action: () => props.getOnlyAgents('girls') },
      { name: AVAILABLE_GIRLS_FILTER, action: () => props.getAvailableGirls('girls') },
      { name: NOT_TEXTED_FOR_LONG, action: () => props.getNotTextedForLong('girls') },
      { name: TEMPORARY_FILTER, action: () => props.getTemporaryGirlContacts('girls') },
      { name: DEBT_FILTER, action: () => props.getDebtGirlContacts('girls') },
      { name: ACTIVE_CHATS, action: () => props.getActiveChats(2) },
    ];

    return filters;
  }, []);

  const stopSearch = useCallback(() => {
    cancelPrevRequest();
    props.stopContactsSearchByType('girls');
  }, []);

  const startSearch = (query) => {
    if (query !== props.searchGirls || prevGirlsSearchSource !== girlsSearchSource) {
      cancelPrevRequest();

      return props.searchContactsByType(query, 'girls', girlsSearchSource, newCancelToken());
    }
  };

  const pending = props.girlsActiveFilter === ALL_FILTER
    ? props.girlsPending
    : props.girlsFilteredPending;

  return (
    <div className="sidebar-sections">
      <SidebarHeader
        className="sidebar-sections__title title"
        type='Girls'
      />

      <div className="sidebar-sections__filters">
        <SidebarContactsFilters
          initialQuery={props.searchGirls}
          activeTab={'girls'}
          startSearch={startSearch}
          stopSearch={stopSearch}
          filters={getFiltersByType()}
          activeSelectFilter={props.activeFilter}
          defaultFilter={ALL_FILTER}
          searchSource={girlsSearchSource}
          contactType='girls'
          toggleSearchSource={props.toggleSearchSource}
          toggleMessageSource={props.toggleMessageSource}
          toggleMainFilter={props.toggleMainFilter}
          filtersCounters={props.filtersCounters}
          girlsMainActiveFilter={props.girlsMainActiveFilter}
        />
      </div>

      <div className="sidebar-sections__list" id='girl-chat-list'>
        <SidebarContactsList
          list={props.girls}
          pending={pending}
          search={props.searchGirls}
          filter={props.girlsActiveFilter}
          updateList={updateGirlList()}
          limit={CHAT_CONTACTS_LIMIT}
          type={2}

          listItem={ContactItem}
          generateContextItems={generateContextItems}

          searchSource={girlsSearchSource}
        />
      </div>
    </div>
  );
};


const mapStateToProps = state => ({
  girls: selectGirlsList(state, 2),
  girlsPending: state.contacts.girls.pending,
  girlsFilteredPending: state.contacts.girls.filteredPending,
  girlsActiveFilter: state.contacts.girls.activeFilter,
  searchGirls: state.contacts.girls.search,
  girlsTotalCount: state.contacts.girls.totalCount,
  girlsUnreadCount: state.contacts.girls.unreadCount,
  activeFilter: state.contacts.girls.activeFilter,
  girlsSearchSource: state.contacts.girls.searchSource,
  filtersCounters: state.contacts.girls.filtersCounters,
  girlsMainActiveFilter: state.contacts.girls.mainActiveFilter,
});

const mapDispatchToProps = {
  updateContactsList,
  updateSearchContactsByType,
  toggleContactPin,
  toggleContactMark,
  getContactsWithUnread,
  getMarkedContacts,
  getAvailableGirls,
  getRelatedContacts,
  toggleContactBlock,
  stopContactsSearchByType,
  searchContactsByType,
  removeContactFilter,
  getOnlyHotels,
  getOnlyAgents,
  getNotTextedForLong,
  getDebtGirlContacts,
  getTemporaryGirlContacts,
  getActiveChats,
  cleanContactsUnreadCount,
  openModal,
  closeAllModals,
  toggleSearchSource,
  toggleMessageSource,
  toggleMainFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarGirlsChatList);
