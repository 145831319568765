import ICONS from 'assets/icons';
import { useDispatch, useSelector } from 'react-redux';

import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import { classModifier } from 'utils';


const HeaderModalMultiMessageBtn = () => {
  const activeModals = useSelector(state => state.activeWindows.activeModals);
  const dispatch = useDispatch();

  const isMultiMessageModalOpen = activeModals.find(modal => modal.type === MODAL_TYPES.multiMessageModal);

  return (
    <button
      className="header-modal-btns__button"
      onClick={() => dispatch(openModal(MODAL_TYPES.multiMessageModal))}
    >
      <ICONS.msgArrows
        className={classModifier("header-modal-btns__icon", isMultiMessageModalOpen && 'active')}
        width="20px"
        height="20px"
      />
    </button>
  )
}

export default HeaderModalMultiMessageBtn;
