import ICONS from 'assets/icons';

import './HeaderModalButtons.scss';
import HeaderModalBookingBtn from './components/HeaderModalBookingBtn';
import HeaderModalTasksBtn from './components/HeaderModalTasksBtn';
import HeaderModalMapBtn from './components/HeaderModalMapBtn';
import HeaderModalMultiMessageBtn from './components/HeaderModalMultiMessageBtn';


const HeaderModalButtons = () => {
  return (
    <div className="header-modal-btns">
      <HeaderModalBookingBtn />
      <HeaderModalMapBtn />
      <HeaderModalTasksBtn />
      <HeaderModalMultiMessageBtn />

      <button className="header-modal-btns__button">
        <ICONS.robot className="header-modal-btns__icon" width="20px" height="20px" />
      </button>
    </div>
  )
}

export default HeaderModalButtons;
